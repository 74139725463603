import React, { useRef } from 'react'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import { CtaBorder, CtaMain, CtaWrapper } from './index.style'
import { TextCta } from '../TextStyles'
import PageTransitionLink from '../PageTransitionLink'
import AnimateFadeIn from '../animation/AnimateFadeIn'

const Cta = ({ type, onClick, href, to, children, ...props }) => {
  const $border = useRef()

  const handleMouseEnter = () => {
    gsap.to($border.current, {
      xPercent: 100,
      duration: 0.4,
      ease: 'power2.inOut',
    })
  }

  const handleMouseLeave = () => {
    gsap.fromTo(
      $border.current,
      {
        xPercent: -100,
      },
      {
        xPercent: 0,
        duration: 0.4,
        ease: 'power2.inOut',
      }
    )
  }

  return (
    <AnimateFadeIn>
      <CtaWrapper
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {type === 'button' && (
          <CtaMain as="button" onClick={onClick} {...props}>
            <TextCta as="span">{children}</TextCta>
          </CtaMain>
        )}

        {type === 'externalLink' && (
          <CtaMain as="a" href={href} onClick={onClick} {...props}>
            <TextCta as="span">{children}</TextCta>
          </CtaMain>
        )}

        {type === 'internalLink' && (
          <PageTransitionLink to={to} onClick={onClick} {...props}>
            <CtaMain>
              <TextCta as="span">{children}</TextCta>
            </CtaMain>
          </PageTransitionLink>
        )}

        <CtaBorder ref={$border} />
      </CtaWrapper>
    </AnimateFadeIn>
  )
}

Cta.propTypes = {
  type: PropTypes.oneOf(['button', 'internalLink', 'externalLink']).isRequired,
  href: function (props, propName) {
    if (
      props['type'] === 'externalLink' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error('Please provide a href prop!')
    }
  },
  to: function (props, propName) {
    if (
      props['type'] === 'internalLink' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error('Please provide a to prop!')
    }
  },
  onClick: function (props, propName) {
    if (
      props['type'] === 'button' &&
      (props[propName] === undefined || typeof props[propName] !== 'function')
    ) {
      return new Error('Please provide an onClick function!')
    }
  },
  children: PropTypes.node.isRequired,
}

Cta.defaultProps = {
  type: 'button',
}

export default Cta
