import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Article from '../components/Article'
import { getResourcesTag } from '../utils/utils'

const ResourcePost = ({ data }) => {
  const { slug: resourcesSlug } = data.contentfulResources
  const {
    excerpt: { excerpt },
    seoImage,
    slug,
    metadata,
    title,
    content,
  } = data.contentfulResource

  return (
    <>
      <Seo
        title={title}
        description={excerpt}
        pathname={`${resourcesSlug}/${slug}`}
        image={seoImage}
      />

      <Article
        landingPageSlug={resourcesSlug}
        label={getResourcesTag(metadata)}
        title={title}
        content={content}
        latestPosts={data.allContentfulResource.nodes}
      />
    </>
  )
}

export default ResourcePost

export const pageQuery = graphql`
  query ResourcePost($slug: String!) {
    contentfulResources {
      slug
    }
    allContentfulResource(limit: 3, filter: { slug: { ne: $slug } }) {
      nodes {
        metadata {
          tags {
            name
          }
        }
        title
        excerpt {
          excerpt
        }
        slug
      }
    }
    contentfulResource(slug: { eq: $slug }) {
      excerpt {
        excerpt
      }
      seoImage {
        gatsbyImageData
      }
      slug
      metadata {
        tags {
          name
        }
      }
      title
      content {
        raw
        references {
          ...ContentfulRichTextReferences
        }
      }
    }
  }
`
