import React from 'react'
import PropTypes from 'prop-types'
import { BlogSingleContent, BlogSingleLatest } from './index.style'
import Spacer from '../Spacer'
import Cta from '../Cta'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import PaginationButton from '../PaginationButton'
import DotLabel from '../DotLabel'
import Icon from '../Icon'
import RichText from '../RichText'
import ArticleCard from '../ArticleCard'
import { Heading2 } from '../TextStyles'
import AnimateSplitText from '../animation/AnimateSplitText'
import { getResourcesTag } from '../../utils/utils'

const Article = ({
  landingPageSlug,
  label,
  title,
  titleIcon,
  content,
  latestPosts,
}) => (
  <>
    <Spacer size={[128, 270]} />

    <Container>
      <Grid>
        <GridItem
          tabletP={4}
          tabletL={4}
          tabletLStart={2}
          desk={3}
          deskStart={2}
        >
          <PaginationButton to={`${landingPageSlug}/`}>
            more posts
          </PaginationButton>
        </GridItem>

        <GridItem tabletP={12} tabletL={10} desk={8}>
          <BlogSingleContent>
            {label && (
              <>
                <DotLabel>{label}</DotLabel>
                <Spacer size={[30, 40]} />
              </>
            )}

            <Heading2 as="h1">
              <AnimateSplitText>
                {title} {titleIcon && <Icon type={titleIcon} />}
              </AnimateSplitText>
            </Heading2>

            <Spacer size={[30, 50]} />

            <RichText content={content} />
          </BlogSingleContent>
        </GridItem>
      </Grid>

      <Spacer size={[60, 160]} />
    </Container>

    <BlogSingleLatest>
      <Spacer size={[60, 160]} />

      <Container>
        <Grid>
          <GridItem tabletP={6} tabletL={5} tabletLStart={2}>
            <Heading2 as="h2">
              <AnimateSplitText>
                Don't miss out on our latest posts
              </AnimateSplitText>
            </Heading2>

            <Spacer size={[20, 45]} />

            <Cta type="internalLink" to={`${landingPageSlug}/`}>
              Show me all
            </Cta>
          </GridItem>

          <GridItem tabletP={10} tabletL={9} desk={7} deskStart={9}>
            {React.Children.toArray(
              latestPosts.map(post => (
                <ArticleCard
                  label={post.date ? post.date : getResourcesTag(post.metadata)}
                  title={post.title}
                  excerpt={post.excerpt?.excerpt}
                  to={`${landingPageSlug}/${post.slug}/`}
                />
              ))
            )}
          </GridItem>
        </Grid>
      </Container>

      <Spacer size={[50, 160]} />
    </BlogSingleLatest>
  </>
)

Article.propTypes = {
  landingPageSlug: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  titleIcon: PropTypes.string,
  content: PropTypes.object,
  latestPosts: PropTypes.array,
}

export default Article
