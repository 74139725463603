import { transparentize } from 'polished'
import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import { ArticleCardMain } from '../ArticleCard/index.style'

export const BlogSingleContent = styled.div`
  padding-top: 2rem;

  ${mq.tabletP} {
    ${clamp('padding-top', 12, 18, breakpoints.tabletP)}
  }
`

export const BlogSingleLatest = styled.div`
  border-top: 0.1rem solid ${transparentize(0.9, colors.dark)};

  ${ArticleCardMain} {
    :first-child {
      ${mq.tabletPMax} {
        margin-top: 2rem;
      }
    }

    + ${ArticleCardMain} {
      ${clamp('margin-top', 20, 30)}
    }
  }
`
