import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'

export const CtaWrapper = styled.div`
  position: relative;
  display: inline-flex;
  overflow: hidden;
`

export const CtaMain = styled.div`
  color: ${colors.dark};
  text-decoration: none;
  background: transparent;
  border: none;

  span {
    display: block;
    padding-bottom: 0.2727272727em;
  }
`

export const CtaBorder = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: 0.1rem;
  background-color: ${colors.green};
`
